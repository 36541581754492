define(['app', '$console'], function(app, $console) {
  var galleryDetails_product = function() {
    var obj = {};
    var _product = {};
    var _quickbuy = {};

    obj.select = {
      hotspots: '.galleryDetails_hotspot',
      wrapper: '.galleryDetails_sideView',
      overlay: '.galleryDetails_sideViewOverlay',
      exit: '.galleryDetails_sideViewExit',
      spinner: '.galleryDetails_sideViewSpinner',
      content: '.galleryDetails_sideViewContent',
      buy: '.galleryDetails_productBuy',
      variation: '[data-variation]',
      options: '[data-options]',
      selected: '[data-selected=true]',
      quantity: '[data-quantity-input]',
      increment: '[data-increment]',
      decrement: '[data-decrement]'
    };

    obj.attr = {
      sku: 'data-options-sku',
      component: 'data-component',
      show: 'data-show',
      quickbuy: 'data-options-quickbuy',
      variationId: 'data-variation-id',
      optionId: 'data-option-id',
      selected: 'data-selected',
      siteId: 'data-site-id',
      locale: 'data-default-locale',
      subsite: 'data-subsite-code',
    };

    obj.config = {
      template: 'components/galleryDetails/partials/product/productInfoWithQuantity',
      templateQuickbuy: 'components/galleryDetails/partials/product/productInfo',
      maxQuantity: 99
    };

    obj.init = function(el) {
      obj.el = el;
      obj.parent = obj.findParent(el);

      if (!obj.parent) {
        $console.error('Can\'t find parent element');
        return;
      }

      obj.hotspots = obj.el.querySelectorAll(obj.select.hotspots);
      obj.wrapper = obj.parent.querySelector(obj.select.wrapper);
      obj.overlay = obj.parent.querySelector(obj.select.overlay);
      obj.exit = obj.parent.querySelector(obj.select.exit);
      obj.spinner = obj.parent.querySelector(obj.select.spinner);
      obj.content = obj.parent.querySelector(obj.select.content);

      _quickbuy.enabled = obj.el.getAttribute(obj.attr.quickbuy) === 'true';
      _product.template = _quickbuy.enabled ? obj.config.templateQuickbuy : obj.config.template;

      obj.bind();
    };

    obj.findParent = function(el) {
      if (!el || document.body === el) {
        return null;
      }

      if (el.getAttribute(obj.attr.component) === 'galleryDetails') {
        return el;
      }

      return obj.findParent(el.parentNode);
    };

    obj.bind = function() {
      obj.overlay.addEventListener('click', _product.close, false);
      obj.exit.addEventListener('click', _product.close, false);

      for (var index = 0; index < obj.hotspots.length; index++) {
        obj.hotspots[index].addEventListener('click', _product.open, false);
      }
    };

    _product.open = function(ev) {
      var el = ev.target;

      obj.sku = parseInt(el.getAttribute(obj.attr.sku)) || 0;
      obj.wrapper.setAttribute(obj.attr.show, 'true');
      obj.overlay.style.display = 'block';
      obj.spinner.style.display = 'block';
      obj.content.innerHTML = '';

      app.publish('tracking/record', 'GalleryDetails', 'clicked', 'open hotspots', obj.sku);

      app.ajax.get({
        url: '/' + obj.sku + '.quickbuy?variationSelect=true&'
          + 'stringTemplatePath=' + _product.template,
        success: _product.onsucces,
        error: _product.onerror
      });
    };

    _product.close = function() {
      obj.wrapper.setAttribute(obj.attr.show, 'false');
      obj.overlay.style.display = 'none';
    };

    _product.onsucces = function(result) {
      if (obj.wrapper.getAttribute(obj.attr.show) !== 'true') {
        return;
      }

      obj.content.innerHTML = result;
      obj.spinner.style.display = 'none';

      if (_quickbuy.enabled) {
        app.publish('productQuickbuy/reinitialise');
      } else {
        _quickbuy.build();
        _quickbuy.bind();
      }

      app.publish('tracking/record', 'GalleryDetails', 'viewed', 'view product', obj.sku);
    };

    _product.onerror = function() {
      app.publish('galleryDetails/alert', 'productAlert');
      _product.close();
    };

    _quickbuy.query = {
      productId: 0,
      siteId: 0,
      siteDefaultLocale: 0,
      siteSubsiteCode: 0,
      quantity: 0
    };

    _quickbuy.build = function() {
      if (!obj.content.children.length) {
        _product.close();
        $console.error('Cannot build query for basket');
        app.publish('galleryDetails/alert', 'defaultAlert');

        return;
      }

      var el = obj.content.children[0];
      var options = obj.content.querySelectorAll(obj.select.selected);

      _quickbuy.query.quantity = 1;
      _quickbuy.query.productId = obj.sku;
      _quickbuy.query.siteId = el.getAttribute(obj.attr.siteId);
      _quickbuy.query.siteDefaultLocale = el.getAttribute(obj.attr.locale);
      _quickbuy.query.siteSubsiteCode = el.getAttribute(obj.attr.subsite);

      _quickbuy.query.variationLength = options.length;

      for (var index = 0; index < options.length; index++) {
        var element = options[index];
        var variation = parseInt(element.getAttribute(obj.attr.variationId));
        var option = element.getAttribute(obj.attr.optionId);

        if (isNaN(variation)) {
          continue;
        }

        _quickbuy.query['variation' + variation] = 'variation' + variation + ':' + option;
      }
    };

    _quickbuy.bind = function() {
      obj.incrementEl = obj.content.querySelector(obj.select.increment);
      obj.decrementEl = obj.content.querySelector(obj.select.decrement);
      obj.quantityEl = obj.content.querySelector(obj.select.quantity);
      obj.buyEl = obj.content.querySelector(obj.select.buy);

      if (obj.buyEl.disabled) {
        return;
      }

      obj.incrementEl.addEventListener('click', _quickbuy.increment, false);
      obj.decrementEl.addEventListener('click', _quickbuy.decrement, false);
      obj.quantityEl.addEventListener('change', _quickbuy.update, false);
      obj.buyEl.addEventListener('click', _quickbuy.buy, false);
    };

    _quickbuy.increment = function(el) {
      _quickbuy.query.quantity = Math.min(_quickbuy.query.quantity + 1, obj.config.maxQuantity);
      obj.quantityEl.value = _quickbuy.query.quantity;
    };

    _quickbuy.decrement = function(el) {
      _quickbuy.query.quantity = Math.max(_quickbuy.query.quantity - 1, 0);
      obj.quantityEl.value = _quickbuy.query.quantity;
    };

    _quickbuy.update = function(el) {
      var value = obj.quantityEl.value;
      value = Math.max(value, 0);
      value = Math.min(value, obj.config.maxQuantity);

      _quickbuy.query.quantity = value;
      obj.quantityEl.value = value;
    };

    _quickbuy.buy = function() {
      obj.buyEl.disabled = true;

      app.ajax.get({
        url: '/basketinterface.json?' + _quickbuy.serialise(),
        success: _quickbuy.onsuccess,
        error: _quickbuy.onerror
      });
    };

    _quickbuy.serialise = function() {
      return Object.keys(_quickbuy.query).map(function(key) {
        return key + '=' + _quickbuy.query[key];
      }).join('&');
    };

    _quickbuy.onsuccess = function(response) {
      obj.buyEl.disabled = false;

      var json;

      try {
        json = JSON.parse(response);
      } catch (e) {
        $console.error('Got exception while parsing basket response');
        $console.error(e);

        app.publish('galleryDetails/alert', 'buyAlert');
        return;
      }

      if (!json || !json.simpleBasket) {
        app.publish('galleryDetails/alert', 'buyAlert');
        return;
      }

      var basket = json.simpleBasket;
      var item = basket.lastAddedItem;

      var data = {
        productId: item.productId,
        productTitle: item.productTitle,
        productUrl: item.productURL,
        productQuantity: _quickbuy.query.quantity,
        productPrice: item.price,
        basketTotalPrice: basket.totalprice,
        recommendations: basket.recommendations,
        basketTotalItems: basket.totalUnits,
        productImageUrl: ''
      };

      if (item.productImages && item.productImages.largeproduct) {
        data.productImageUrl = item.productImages.largeproduct;
      }

      app.publish('addedToBasketModal/productAdded', data, false);
      app.publish('globalBasketItemsCount/updateGlobalBasketItemsCount', data.basketTotalItems);
      app.publish('BasketItemAdded', json);
    };

    _quickbuy.onerror = function() {
      obj.buyEl.disabled = false;
      app.publish('galleryDetails/alert', 'buyAlert');
    };

    obj.product = _product;
    obj.quickbuy = _quickbuy;

    return obj;
  };

  return galleryDetails_product;
});
